import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'skuSelector',
    'batchSelector',
    'parentSelector',
    'parentLineItemSku',
  ];

  static values = {
    variantIdDescriptionMap: Object,
    batchIdNameMap: Object,
    editingEnabled: Boolean,
    defaultSku: String,
    configurableLineItemsMap: Object,
  };

  skuSelectorTargetConnected(select) {
    if (!this.editingEnabledValue) {
      const { currentVariantId, currentVariantSku } = select.dataset;
      this.setDisabledState(select, currentVariantId, currentVariantSku);
      return;
    }

    const { currentVariantId } = select.dataset;
    this.addSelectOptions(
      select,
      this.variantIdDescriptionMapValue,
      currentVariantId,
    );
  }

  batchSelectorTargetConnected(select) {
    if (!this.editingEnabledValue) {
      const { currentBatchId } = select.dataset;
      const currentBatchName = this.batchIdNameMapValue[currentBatchId];
      this.setDisabledState(select, currentBatchId, currentBatchName);
      return;
    }

    const { currentBatchId } = select.dataset;
    this.addSelectOptions(select, this.batchIdNameMapValue, currentBatchId);
  }

  parentLineItemSkuTargetConnected(target) {
    const { currentParentId } = target.dataset;
    const currentParentSku =
      this.configurableLineItemsMapValue[currentParentId];

    if (currentParentSku) {
      target.textContent = `${currentParentSku} (ID: ${currentParentId})`;
    }
  }

  parentSelectorTargetConnected(select) {
    const configurableLineItemsMap = Object.entries(
      this.configurableLineItemsMapValue,
    ).map(([parentId, parentSKU]) => ({
      id: parentId,
      text: `${parentSKU} (ID: ${parentId})`,
    }));

    if (Object.keys(configurableLineItemsMap).length > 0) {
      configurableLineItemsMap.unshift({ id: 'none', text: 'None' });

      $(select).select2({
        placeholder: 'Select a parent',
        data: configurableLineItemsMap,
        minimumResultsForSearch: 10,
        dropdownAutoWidth: true,
      });

      $(select).val(select.dataset.currentParentId).trigger('change');
    } else {
      select.remove();
    }
  }

  setDisabledState(select, defaultOptionId, defaultOptionName) {
    const defaultOption = new Option(
      defaultOptionName,
      defaultOptionId,
      null,
      true,
    );
    select.options.add(defaultOption);
    select.disabled = true;
    select.nextElementSibling?.classList.remove('hidden');
  }

  addSelectOptions(select, optionsMap, selectedOption) {
    Object.entries(optionsMap).forEach(([id, description]) => {
      const selected = id.toString() === selectedOption.toString();
      const option = new Option(description, id, null, selected);
      select.options.add(option);
    });
  }
}
