import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];
  static outlets = ['expandable-list'];
  static values = { open: String, showText: String, hideText: String };

  toggle() {
    if (this.openValue == 'true') {
      this.hide();
      this.openValue = 'false';
      this.buttonTarget.innerHTML = this.showTextValue;
    } else {
      this.show();
      this.openValue = 'true';
      this.buttonTarget.innerHTML = this.hideTextValue;
    }
  }

  show() {
    this.expandableListOutlets.forEach((expandableList) => {
      expandableList.show();
    });
  }

  hide() {
    this.expandableListOutlets.forEach((expandableList) => {
      expandableList.hide();
    });
  }
}
