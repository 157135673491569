import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['productSelect', 'variantBundlingsFrame'];
  static values = { path: String };

  connect() {
    $(this.productSelectTarget)
      .select2()
      .on('change', () => this.selectProduct());
  }

  selectProduct() {
    const bundledProductId = this.productSelectTarget.value;
    this.variantBundlingsFrameTarget.src =
      this.pathValue + '?bundled_product_id=' + bundledProductId;
  }
}
